import React from 'react'
import ServiceSidebarb from './ServiceSidebarb'
import details1b from '../../assets/images/services/services-details1b.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentb = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1b} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data Migration</span>
                            <h3>About this Service</h3>
                            <p>No matter what industry you work in, there is a high chance that your company will need to transfer large sets of data from one platform to another at some point. Known as data migration, this process can help your company store a larger amount of data at a time, maintain the integrity of said data, and boost productivity. </p>
                            <p>Our data migration service will help you with inaccurate data migration because without precise inventory, accurate customer information, impeccable accounting, and other data-driven information, you would be hard-pressed to compete in today's marketplace. Our data migration will help you in the process of transferring data between computer storage types or file formats. You might need to transfer data from hardware to cloud-based systems, or from a non-SAP system to an SAP system, or upgrade from an older SAP format to the newest SAP HANA. In any event, data migration must be handled carefully. Be sure to call the experts at 1st Basis to deal with any data migration you might need. Organization of data migration is the first step. </p>
                            <p>That includes, Data Cleansing, Mapping, and conversion rules Extract and Load Programs from rules, Load Unit Testing (unitary testing-a-small volume of manual data), Extract and Load Full-size testing (data test and validation-large volume with real extracted data). </p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>Analysis and discovery</li>
                                            <li>Data Validation</li>
                                            <li>Reconciliation</li>
                                            <li>Data transformation</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Our data migration service ensures comprehensive data integrity and reduces storage costs. Minimizes disruption to daily business operations with minimal manual efforts. We will provide your company with the standards for data quality, data mapping, and data conversion and loading. Our data migration process involves key data stakeholders throughout the data migration project to mechanize the execution of mapping of data, data transformation, and data quality responsibilities. That will set up monitoring and reporting and establish an action plan for data remediation.</p>
                            <h3>Application Areas</h3>
                            <p>Related Industries/Application Domains</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Manufacturing
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Banking Sector
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Insurance Companies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Information and technologies
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Utilities
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Chemical
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Pharmaceuticals
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Retail
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Oil and Energy
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>BW</span></li>
                                <li><span>HANA</span></li>
                                <li><span>Batch Data Conversion</span></li>
                                <li><span>BODS � Business Objects Data Services</span></li>
                                
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebarb />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentb