import React from 'react'
import { Link } from 'gatsby'
import icon1 from '../../assets/images/services/service-icon1.png'
import icon2 from '../../assets/images/services/service-icon2.png'
import icon3 from '../../assets/images/services/service-icon3.png'

const RelatedServicesb = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>More Services You Might Like</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsd">
                                    Controlling
                                </Link>
                            </h3>
                            <p>Helping you implement SAP CO module and optimizing your organizations reporting & management structures.</p>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsg">
                                    Project Systems
                                </Link>
                            </h3>
                            <p>Implement SAP PS module & improve your Project Management process such as cost and budget management, scheduling, requisitioning of materials and services.</p>


                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon3} alt="about" />
                            </div>
                            <h3>
                                <Link to="/service-detailsh">
                                    BEX & BW
                                </Link>
                            </h3>
                            <p>Helping you implement SAP Business Explorer & Business Warehouse solutions to improve your business decision making processes.</p>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RelatedServicesb